import React from 'react';
import './BwHeadline.css'

interface BwHeadlineProps {
  headline: string;
}

const BwHeadline: React.FC<BwHeadlineProps> = ({ headline }) => {
  return (
    <div className="bw-text-container">
      <h1 className="bw-headline">{headline}</h1>
    </div>
  );
};

export default BwHeadline;