// src/components/ExcitingLink/ExcitingLink.tsx
import React from 'react';
import './ExcitingLink.css';

interface ExcitingLinkProps {
  headline: string;
  subheadline: string;
  link: string;
  cta: string;
}

const ExcitingLink: React.FC<ExcitingLinkProps> = ({ headline, subheadline, link, cta }) => {
  return (
    <div className="exciting-link">
      <h2 className="exciting-link-headline">{headline}</h2>
      <p className="exciting-link-subheadline">{subheadline}</p>
      <a href={link} className="exciting-link-button" target="_blank" rel="noopener noreferrer">
        {cta}
      </a>
    </div>
  );
};

export default ExcitingLink;