import React from 'react';
import OptionTile from './OptionTile';
import './OptionTileGrid.css';

interface OptionTileGridProps {
  options: {
    logoSrc: string;
    logoAlt: string;
    headline: string;
    text: string;
  }[];
}

const OptionTileGrid: React.FC<OptionTileGridProps> = ({ options }) => {
  return (
    <div className="option-tile-grid">
      {options.map((option, index) => (
        <OptionTile
          key={index}
          logoSrc={option.logoSrc}
          logoAlt={option.logoAlt}
          headline={option.headline}
          text={option.text}
        />
      ))}
    </div>
  );
};

export default OptionTileGrid;