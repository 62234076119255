import React from 'react';
import ReactMarkdown from 'react-markdown';
import './OptionTile.css';

interface OptionTileProps {
  logoSrc: string;
  logoAlt: string;
  headline: string;
  text: string; // This will accept markdown
}

const OptionTile: React.FC<OptionTileProps> = ({ logoSrc, logoAlt, headline, text }) => {
  return (
    <div className="option-tile">
      <div className="logo-container">
        <img src={logoSrc} alt={logoAlt} className="logo" />
      </div>
      <h2 className="option-headline">{headline}</h2>
      <ReactMarkdown className="option-text" children={text} />
    </div>
  );
};

export default OptionTile;