// src/hooks/useDynamicStyles.ts
import { useEffect } from 'react';

const useDynamicStyles = () => {
    useEffect(() => {
        // Select the .App, .App-header, and .navbar elements directly
        const appElement = document.querySelector('.App') as HTMLElement;
        const navbarElement = document.querySelector('.navbar') as HTMLElement;
        
    
        // Change .App background if it exists
        if (appElement && navbarElement) {
          const originalAppBackground = appElement.style.background;
          appElement.style.background = 'linear-gradient(135deg, #000000, #000000)';
    
          const originalNavbarBackground = navbarElement.style.background;
          navbarElement.style.background = 'linear-gradient(90deg, #000000, #000000, #000000)'; // New gradient color
    
          // Cleanup function
          return () => {
            appElement.style.background = originalAppBackground;
            navbarElement.style.background = originalNavbarBackground;
          };
        }
      }, []); // Empty dependency array to run on mount and unmount only
};

export default useDynamicStyles;
