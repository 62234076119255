import React from 'react';
import ReactMarkdown from 'react-markdown';
import './ImageText.css'; // Assuming this file contains your default (cyan) theme styles

interface ImageTextProps {
  imageSrc: string;
  imageAlt: string;
  headline: string;
  text: string; // This will now accept Markdown
  theme?: 'default' | 'dark-red'; // Add theme prop
}

const ImageText: React.FC<ImageTextProps> = ({ imageSrc, imageAlt, headline, text, theme = 'default' }) => {
  return (
    <div className={`image-text ${theme}`}>
      <h3 className="headline">{headline}</h3>
      <div className="content-container">
        <div className="image-container">
          <img src={imageSrc} alt={imageAlt} className="image" />
        </div>
        <div className="text-container">
          <ReactMarkdown className="text" children={text} />
        </div>
      </div>
    </div>
  );
};

export default ImageText;