import React from 'react';
import './ProsAndCons.css';

interface ProsAndConsProps {
  pros: string[];
  cons: string[];
}

const ProsAndCons: React.FC<ProsAndConsProps> = ({ pros, cons }) => {
  return (
    <div className="pros-and-cons">
      <div className="pros-cons-column pros">
        <h3>Perfect Match</h3>
        <ul>
          {pros.map((pro, index) => (
            <li key={index}>{pro}</li>
          ))}
        </ul>
      </div>
      <div className="pros-cons-column cons">
        <h3>Might not work</h3>
        <ul>
          {cons.map((con, index) => (
            <li key={index}>{con}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProsAndCons;