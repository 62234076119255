export const contacts = [
    {
      logoSrc: `${process.env.PUBLIC_URL}/images/emaillogo.svg`,
      logoAlt: 'Email',
      firstPart: 'mikhail.syvachenko',
      secondPart: 'gmail.com',
      contactText: 'Email Me',
      contactType: 'mail'
    },
    {
      logoSrc: `${process.env.PUBLIC_URL}/images/linkedinlogo.svg`,
      logoAlt: 'LinkedIn',
      firstPart: 'https://www.linkedin.com/in/mikhail-syvachenko',
      secondPart: '',
      contactText: 'LinkedIn',
      contactType: 'url'
    },
    {
      logoSrc: `${process.env.PUBLIC_URL}/images/telegramlogo.svg`,
      logoAlt: 'Telegram',
      firstPart: 'https://t.me/sivach',
      secondPart: '',
      contactText: 'Telegram',
      contactType: 'url'
    },
];