// src/components/Projects/Projects.tsx
import React from 'react';
import ExcitingLink from '../ExcitingLink/ExcitingLink';
import ImageText from '../ImageText/ImageText';
import useDynamicStyles from './useDynamicStyles';

const Projects: React.FC = () => {
  useDynamicStyles();

  return (
    <div>
      <ExcitingLink 
        headline="MVPs, Projects, Playground" 
        subheadline="There is no free time. You owe others. When job's done - you owe yourself." 
        link="https://projects.sivach.me"
        cta='To Project α'
      />
      <ImageText 
        imageSrc={`${process.env.PUBLIC_URL}/images/sivachdocu2.png`}
        imageAlt="AI Documentation Visualizer"
        headline="ContAI"
        theme='dark-red'
        text={`
**Get the job done faster:**\n
Powered by OpenAI the project is aiming to extract and visualize the data around the tasks and projects you get to work on.\n
Improved onboarding experience / Documentation is maintained / Conceptualized by AI for Humans \n
Development is in progress.`}
      />
    </div>
  );
};

export default Projects;