import React from 'react';
import './HeroText.css';

interface HeroTextProps {
  headline: string;
  subheadline: string;
}

const HeroText: React.FC<HeroTextProps> = ({ headline, subheadline }) => {
  return (
    <div className="hero-text-container">
      <h1 className="hero-headline">{headline}</h1>
      <h2 className="hero-subheadline">{subheadline}</h2>
    </div>
  );
};

export default HeroText;