import React, { useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import Professional from './components/Professional/Professional';
import Projects from './components/Projects/Projects';
import Personal from './components/Personal/Personal';
import './App.css'; // Import the global styles

const App: React.FC = () => {
    const [currentNavItemIndex, setCurrentNavItemIndex] = useState(0);

    const renderComponent = () => {
        switch (currentNavItemIndex) {
            case 0:
                return <Professional />;
            case 1:
                return <Projects />;
            case 2:
                return <Personal />;
            default:
                return <Professional />;
        }
    };

    return (
        <div className="App">
            <Navbar setCurrentNavItemIndex={setCurrentNavItemIndex} />
            <main className="main-content">
                {renderComponent()}
            </main>
        </div>
    );
};

export default App;