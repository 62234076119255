import React, { useState } from 'react';
import './Navbar.css';

interface NavbarProps {
    setCurrentNavItemIndex: (index: number) => void;
}

const Navbar: React.FC<NavbarProps> = ({ setCurrentNavItemIndex }) => {
    const [navigationItems] = useState(['Professional', 'Projects', 'Personal']);
    const [currentNavItemIndex, setCurrentNavItemIndexLocal] = useState(0);

    const handleNavClick = () => {
        const newIndex = (currentNavItemIndex + 1) % navigationItems.length;
        setCurrentNavItemIndexLocal(newIndex);
        setCurrentNavItemIndex(newIndex);
    };

    return (
        <nav className="navbar">
            <ul className="navbar-links">
                <li onClick={handleNavClick}>
                    <a href={`#${navigationItems[currentNavItemIndex].toLowerCase()}`} className="navbar-link">
                        <div className="navbar-link-content">
                            {navigationItems[currentNavItemIndex]}
                            <br />
                            <span className="navbar-click-hint">Click to navigate</span>
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
