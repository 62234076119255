import React, { useEffect, useState } from 'react';
import './ImageRotator.css'

interface ImageRotatorProps {
  imageNames: string[]; // Array of image filenames passed via props
}

const ImageRotator: React.FC<ImageRotatorProps> = ({ imageNames }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    // Set an interval to update the current image index every 5 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageNames.length);
    }, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [imageNames.length]);

  // Resolve the image path from the public folder
  const resolveImagePath = (imageName: string) => {
    return `${process.env.PUBLIC_URL}/images/${imageName}`; // Assuming images are in the 'public/images' directory
  };

  // If no images are passed, display a fallback message
  if (imageNames.length === 0) {
    return <div>No images available.</div>;
  }

  return (
    <div className="image-rotator">
      <img
        src={resolveImagePath(imageNames[currentIndex])}
        alt={`Slide ${currentIndex + 1}`}
        className="rotating-image"
      />
    </div>
  );
};

export default ImageRotator;