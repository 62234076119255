export const options = [
    {
      logoSrc: `${process.env.PUBLIC_URL}/images/techpower.svg`,
      logoAlt: 'Technical Expertise Image',
      headline: 'Learn & Share',
      text: `
Technical expertise played key role in my development. I am always open to leaning more about following topics, as well as sharing expertise:\n
\n\b\n
**Key knowledge:**\n
C# / .NET / ASP.NET Core\n
E-Commerce Solutions\n
Azure Cloud / DevOps Culture\n
\n\b\n

Constant learning is key. If you share knowledge or passion for one of the following topics, I would be keen to connect with you and learn from your expeience:\n
\n\b\n
**Key interests:**\n
AI Governance\n
SaaS Bootstrapping\n
Privacy & Security\n
`,
    },
    {
      logoSrc: `${process.env.PUBLIC_URL}/images/brainbulb.svg`,
      logoAlt: 'Soft Skills Image',
      headline: 'Inspire Renown',
      text: `
Nobody can live in obscurity. This is a simple fact - yet many people are scared to admit they lack publicity. I want to inspire you for joining the journey of openness, where every word has value.
\n\b\n
I believe this can be a start point, where *One-Man-Army* individuals get together and reach greater joined results. If you have skills, which belong to the following list...\n

\n\b\n
**Your perspective will be valued**:\n
Sales\n
Marketing\n
Sociology\n
Management\n
Delivery\n
Education\n
Accounting\n
`,
    },
    {
      logoSrc: `${process.env.PUBLIC_URL}/images/humannet.svg`,
      logoAlt: 'Growth Mindset Image',
      headline: 'Break The Limits',
      text: `
**Time is limited. Life is not.**\n
Everything is possible when professionals find strength to work with others. Throughout my career, I've witnessed teams united by a common mission achieve results far beyond expectations.
\n\b\n
Everyone is unique at how they find strength to move forward. Infinite ways to generate energy, which fuels success of thousands of people worldwide every day.
\n\b\n
Even for those, who decide not to join this movement or randomly ends up on this page, I wish that the content will inspire to go beyond what they believe is possible. And if you are willing to take the challenge, there is not a better way to break the limits than by reaching out. 
`,
    },
  ];