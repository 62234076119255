import React from 'react';
import './ContactDetail.css';

interface ContactDetailProps {
  logoSrc: string;
  logoAlt: string;
  firstPart: string;
  secondPart: string;
  contactText: string;
  contactType: string;
}

const ContactDetail: React.FC<ContactDetailProps> = ({ logoSrc, logoAlt, firstPart, secondPart, contactText, contactType }) => {
  return (
    <div className="contact-detail">
      <img src={logoSrc} alt={logoAlt} className="contact-logo" />
      <a
        href={contactType == 'mail' ? `mailto:${firstPart}@${secondPart}` : firstPart }
        className="contact-link"
        aria-label={contactText}
      >
        {contactText}
      </a>
    </div>
  );
};

export default ContactDetail;