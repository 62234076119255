import React from 'react';
import './ReachMe.css';
import ContactDetail from '../ContactDetail/ContactDetail';

interface ReachMeProps {
  headline: string;
  text: string;
  contacts: {
    logoSrc: string;
    logoAlt: string;
    firstPart: string;
    secondPart: string;
    contactText: string;
    contactType: string;
  }[];
}

const ReachMe: React.FC<ReachMeProps> = ({ headline, text, contacts }) => {
  return (
    <div className="reach-me">
      <h2 className="reach-me-headline">{headline}</h2>
      <p className="reach-me-text">{text}</p>
      <div className="contact-details">
        {contacts.map((contact, index) => (
          <ContactDetail
            key={index}
            logoSrc={contact.logoSrc}
            logoAlt={contact.logoAlt}
            firstPart={contact.firstPart}
            secondPart={contact.secondPart}
            contactText={contact.contactText}
            contactType={contact.contactType}
          />
        ))}
      </div>
    </div>
  );
};

export default ReachMe;